import { Helmet } from "react-helmet";
import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import Footer from "./Footer_2";
import ScrollProgressBar from "./ScrollProgressBar";
import Header from "./NavBar";
import BotonWhatsapp from "./botonWhatsapp/BotonWhatsapp";
import { FaPhone } from "react-icons/fa";

function BaseLayout(props) {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <Helmet>
        <title>
          {props.PageName} | {`${rpdata?.dbPrincipal?.name}`}
        </title>
        <meta
          name="description"
          content={`${rpdata?.dbAbout?.[0].text.substring(0, 150) + "..."}`}
        />
        <meta
          name={` ${rpdata?.dbPrincipal?.name}`}
          content={` ${rpdata?.dbPrincipal?.name}`}
        ></meta>
        <meta name="robots" content="index,follow" />
        <meta name="googlebot" content="index,follow" />
        <link rel="icon" href={rpdata?.dbPrincipal?.favicon} />
      </Helmet>
      <Header />
      <ScrollProgressBar />

      <main>{props.children}</main>

      <div className="fixed bottom-0 bg-[#2C622A] mx-4 md:mb-4 mb-20 text-white rounded-lg">
        <a
          href={`tel:+1${rpdata?.dbPrincipal?.phones?.[0].phone}`}
          className="flex self-center items-center p-3"
        >
          <FaPhone className="mx-2" />
          <span className="text-[18px]">
            {" "}
            {rpdata?.dbPrincipal?.phones?.[0].phone}
          </span>
        </a>
      </div>
      {rpdata?.simpleWidgets?.map((item) => {
        if (item.val === "WhatsappBtn" && item.activo === true) {
          return <BotonWhatsapp />;
        }
        if (item.val === "VisitsCounter" && item.activo === true) {
          return <div className="visor_Counter z-50 md:block hidden"></div>;
        }
        return null;
      })}

      <Footer />
    </>
  );
}

export default BaseLayout;
